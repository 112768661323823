const Project = ({ title, description, image, link, linkText, color }) => {
  return (
    <div className="project">
      <div className="projectTopContainer">
        <h1>{title}</h1>
        <div className="projectContentContainer">
          <img src={image} alt={title} style={{ "background-color": color }} />
          <div className="projectDescriptionContainer">
            <div></div>
            <p>{description}</p>
            <div className="projectLink">
              <a href={link} target="_blank" rel="noopener noreferrer">
                {linkText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
